import React, { useState } from "react"
import Helmet from "react-helmet"
import { graphql, Link, navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import RemoveHTML from "../components/removeHtml"
import { Modal } from "react-bootstrap"
import { Collapse } from "reactstrap"
import { Tabbordion, TabPanel, TabLabel, TabContent } from "react-tabbordion"
import { Button } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"
import close_icon from "../images/closed_icon-blue.svg"
import debitForm from "../pdf/automatic-debt-authorization-form.pdf"
import useLiveControl from "../controlled/useLiveControl"
import ResendLoanDocsPopup from "../components/resendLoanDocsPopup"

// accordian
const blockElements = {
  animator: "accordion-animator",
  content: "accordion-content",
  panel: "accordion-panel",
  label: "accordion-title",
}

const customLink = (url, e) => {
  // browserHistory.push('/'+url);
  navigate(url)
}



const DownloadFile = url => {
  window.open(url, "_blank")
}

const HomeOwnerPage = ({ data }) => {
  const node = data.wpgraphql.page
  const [collapse, setCollapse] = useState(false)
  const [accLogin, togglePanel] = useState(false)
  const loginForm = () => {
    togglePanel(true)
  }

  const closeLoginForm = () => {
    togglePanel(false)
  }

  const toggle = e => {
    e.preventDefault()
    setCollapse(!collapse)
  }

  const [value, setValue] = useState("")
  const handleChange = event => {
    setValue(event.target.value)
  }

  const [open, setOpen] = useState(false)

 // const { isStaging } = useLiveControl()

  return (
    <Layout>
      <Seo
        title={"Home Improvement Lending"}
        description={
          "Start your home remodeling project with home improvement financing and loans through EnerBank USA. Get approved and get started today."
        }
      />
      <Helmet>
        <body id="rebrand" className="page-homeowner" />
      </Helmet>
        <section 
        className="banner-area"
        id="homeowner-page-banner-section">
          <div className="container banner-container"
              style={{
                        backgroundImage:
                          "url(" +
                          "https://gbdevpress.enerbank.com/wp-content/uploads/2022/11/homeowner-hero.jpg" +
                          ")",
                      }}
                  >
                <div className="relative banner-area-text-container">
                    <div className="container text-center">
                      <div className="identifier-container row-fluid">
                        <div className="element span12">
                            <div className="rhif-identifier span5">
                                <h4>HOME IMPROVEMENT FINANCING</h4>
                            </div>
                            <div className="right-angle span2"></div>
                        </div>
                      </div>
                      <h1 className="text-white text-left desktop" style={{ padding: "80 0" }}>
                        Your trusted<br/> home improvement lender
                      </h1>
                    </div>
                  </div>
          </div>
          <div className="container text-center mobile">
            <h1 className="text-white text-left" style={{ padding: "80 0" }}>
              Your trusted home improvement lender
            </h1>
          </div>
      </section>
      <section className="service-area section-gap pt-5" id="whychoosebank">
        <div className="container">
          <div className="col-md-12 mb-5">
            <h2 className="text-center">I'm interested in...</h2>
          </div>
          <div className="icon-cards row justify-content-center mb-5">
            <div className="col-lg-3">
              <Link className="card-link" to="#account-login" onClick={loginForm}>
                <div className="card">         
                    <div className="card-image make-payment">
                        <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/user-lock.png" className="" alt="Account login" width="60" />
                    </div>
                    <div className="card-body">
                        <h3>Account<br/>Login <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                    </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3">
            <Button
                  variant=""
                  className="btn sendLoanDoc"
                  onClick={() => setOpen(true)}
                >
                <div className="card">
                    <div className="card-image">
                        <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/invoice.png" className="icon" alt="Resend loan docs" width="50" />
                    </div>
                    <div className="card-body">
                        <h3>Resend Unsigned Loan Documents <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                    </div>
                </div>
              </Button>
            </div>
            <div className="col-lg-3">
              <Link className="card-link" to="https://www.mysecuredock.com/Express/landing.aspx?ss=D1228B42-9BA0-4841-A209-98E6250A15B5">
                <div className="card">
                    <div className="card-image">
                        <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/document-upload.png" className="icon" alt="Upload verification docs" width="50" />
                    </div>
                    <div className="card-body">
                        <h3>Upload Verification Documents <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                    </div>
                  </div>
                </Link>
            </div>
            <div className="col-lg-3">
              <Link className="card-link" to="homeimprovement.rhif.regions.com/homeowner-referral">
                <div className="card">
                    <div className="card-image">
                        <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/05/person-circle-arrows.png" className="icon" alt="Refer a contractor" width="70" />
                    </div>
                    <div className="card-body">
                        <h3>Refer a<br/> Contractor <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                    </div>
                  </div>
                </Link>
            </div>
          </div>
          <div className="row justify-content-center">
              <div className="col-md-12 header-text">
                <p className="has-normal-font-size py-2">
                Regions Home Improvement Financing is a specialized home improvement lender &#8208; that means we&apos;re experienced when it comes to providing loans for home improvement projects like yours. Since it&apos;s our specialty, we&apos;ve developed payment options that are tailored to home improvement needs. We&apos;ve been doing this for over two decades and have approved over a million home improvement loans.
                </p>

                <p className="has-normal-font-size py-2">
                You can get the entire project you want completed right now instead of waiting to save up the cash or completing it in stages. With a Regions Home Improvement Financing loan, your home is not used as collateral, there&apos;s no application fee, no closing or appraisal costs, and no prepayment penalties. Your contractor will offer you a choice of payment options, then you can use one of our fast and easy application methods to connect with our US based lending team to receive a credit decision within seconds. Approvals are valid for 120-180 days, giving you plenty of time to plan and complete your home improvement project.
                </p>
              </div>
            </div>
            </div>
            <div className="container-lg">
              <div className="icon-cards benefit-option row justify-content-center pt-5">
                <div className="col-md-2">
                    <div className="card justify-content-inherit">         
                        <div className="card-image">
                            <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/3-quick-easy-paperless-application-methods.png" className="" alt="Quick and easy application process" width="60" />
                        </div>
                        <div className="card-body">
                            <h3>Quick and Easy application process</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="card justify-content-inherit">
                        <div className="card-image">
                            <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/us-based-lending-customer-service.png" className="icon" alt="U.S.Based Lending & Customer Service" width="50" />
                        </div>
                        <div className="card-body">
                            <h3>U.S. Based Lending & Customer Service</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="card justify-content-inherit">
                        <div className="card-image">
                            <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/calandar.png" className="icon" alt="120-180 Day Credit Commitment" width="50" />
                        </div>
                        <div className="card-body">
                            <h3>120-180 Day Credit Commitment</h3>
                        </div>
                      </div>
                </div>
                <div className="col-md-2">
                    <div className="card justify-content-inherit">
                        <div className="card-image">
                            <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/fdic-insured.png" className="icon" alt="FDIC Insured" width="70" />
                        </div>
                        <div className="card-body">
                            <h3>FDIC<br/> Insured</h3>
                        </div>
                      </div>
                </div>
                <div className="col-md-2">
                    <div className="card justify-content-inherit">
                        <div className="card-image">
                            <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/high-approval-rates.png" className="icon" alt="High approlval rates" width="70" />
                        </div>
                        <div className="card-body">
                            <h3>High Approval Rates</h3>
                        </div>
                      </div>
                </div>
            </div>
        </div>
      </section>

      <section className="section-gap bg-light-gray" id="howtopay">
            <div className="container">
                <div className="col-md-12 mb-5">
                    <h2 className="text-center mb-4">Six Ways to Make a Payment</h2>
                </div>
            <div className="row">
                <div className="col-md-12">
                <Tabbordion
                        blockElements={blockElements}
                        animateContent={"height"}
                        mode={"toggle"}
                        className="regions-accordion"
                        name="accordion" >
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">Make a payment online</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                              <ul>
                                <li>If your account number starts with 456, <Link classname="eb-link" to="https://account.enerbank.com/">log in to your Regions Home Improvement Financing account here</Link>. <br/>
                                If your account number begins with numbers other than 456, <Link classname="eb-link" to="https://payments.enerbank.com/">log in to your Regions Home Improvement Financing account here</Link>.</li>
                                <li>There&#39;s no charge to schedule one-time or recurring payments from a debit card or personal checking or savings account.</li>
                                <li>Note that Regions Bank accepts payments for loans using a debit card, but we do not accept credit cards. </li>
                                <li><Link classname="eb-link" to="/text-to-pay-feature/">Learn more about text-to-pay</Link>.</li>
                              </ul>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">Schedule recurring payments at no charge:</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>          
                                </div>
                            </TabLabel>
                            <TabContent>
                              <ul>
                                <li>Please contact your relationship manager or call{" "} <a href="tel:888.390.1220">888.390.1220</a>, option 1 or</li>
                                <li>Complete the <Link classname="eb-link" to="https://gbdevpress.enerbank.com/wp-content/uploads/2024/09/automatic-debt-authorization-form.pdf" target="_blank">automatic debit form</Link> and return it to: <br/>
                                <strong>Regions<br/>
                                650 S Main St, Suite 1000<br/>
                                Salt Lake City, UT 84101</strong> </li>
                                <li>Note that Regions Bank accepts payments for loans using a debit card, but we do not accept credit cards. </li>
                              </ul>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">Make an online bill payment through your financial institution:</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                              <p>Set up Regions Bank as a new payee with your financial institution&#39;s online bill payment service. </p>
                              <p>Set up a payment with the payment amount due to Regions Bank. </p>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className="btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">Mail your payment with a payment coupon:</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                              <ul>
                                <li>If your account number starts with 456:  <br/>
                                <strong>Regions<br/>
                                PO Box 30122 <br/>
                                Salt Lake City, UT 84130-0122 </strong> </li>
                                <li>If your account number begins with numbers other than 456: <br/>
                                <strong>Regions <br/>
                                PO Box 26856 <br/>
                                Salt Lake City, UT 84126-0856 </strong> </li>
                              </ul>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className=" btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">Send a payment via overnight delivery:</span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                            <p><strong>Regions Home Improvement Financing<br/>
                              650 S Main St, Suite 1000 <br/>
                              Salt Lake City, UT 84101 </strong></p>
                            </TabContent>
                        </TabPanel>
                        <TabPanel>
                            <TabLabel className=" btn btn-link">
                                <div className="row-fluid">
                                    <div className="col-11">
                                        <span className="title-link">Make a payment over the phone: </span>
                                    </div>
                                    <div className="col-1">
                                        <span className="accordion-icon"></span>
                                    </div>
                                </div>
                            </TabLabel>
                            <TabContent>
                              <ul>
                                <li>Call Customer Service at{" "} <a href="tel:888.390.1220">888.390.1220</a>, and choose option 1 </li>
                                <li>There&#39;s no charge to schedule one-time or recurring payments from a debit card or personal checking or savings account. </li>
                                <li>Note that Regions Bank accepts payments for loans using a debit card, but we do not accept credit cards. </li>
                              </ul>
                            </TabContent>
                        </TabPanel>
                    </Tabbordion>
                </div>
            </div>
            <div className="row justify-content-center align-items-top pt-5">
              <div className="col-md-12 text-center mb-3">
                <Button variant="" className="btn btn-green" 
                onClick={loginForm}>
                  Account Login</Button>
              </div>
              <div className="col-md-12 text-center">
                <Button variant="" className="btn btn-green" 
                onClick={() => DownloadFile('https://gbdevpress.enerbank.com/wp-content/uploads/2024/09/automatic-debt-authorization-form.pdf')}>
                  Automatic Debit Form (PDF)</Button>
              </div>
              <div className="col-md-12 text-center py-5">
                <p>Question about a loan? Have a comment? We want to hear from you.</p>
                <button
                  className="btn btn-info btn-green mt-3"
                  onClick={() =>
                    customLink("/feedback/")}
                >
                  Leave Feeback
                </button>
              </div>
            </div>
          </div>
      </section>
      {accLogin && (
        <Modal show={accLogin} animation={true} centered={true} size="md" className="account-login">
          <button
            type="button"
            id="closeButton"
            className="btn-link"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeLoginForm}
            style={{ opacity: 1 }}
          >
            {/* <img
              src={close_icon}
              width="22"
              alt="close icon"
              style={{ fill: "#4d4d4d" }}
            /> */}X
          </button>
          <div className="p-4">
            <h4 className="modal-title mb-4" id="modalTitle">
              Borrower Account Log In
            </h4>
            <div id="modalContent" className="small line-height-normal">
              <p style={{ lineHeight: "normal" }}>
              <span className="d-block mb-3">If your account number starts with 456 </span>
                <a
                  href="https://account.enerbank.com"
                  className="btn btn-info b btn-lifeGreen"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click Here{" "}
                </a>
              </p>
              <p style={{ lineHeight: "normal" }}>
              <span className="d-block mb-3">Otherwise </span>
                <a
                  href="https://enerbankpayments.com/"
                  className="btn btn-info b btn-lifeGreen"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Click Here
                </a>
              </p>
              <p className="thirdparty">
                <small>
                  Third party service providers host the online payment
                  platforms for Regions, and they are not an
                  affiliate of Regions Bank. Use of the payment
                  platforms is subject to third party service providers' terms
                  of use and privacy policies which are provided at the payment
                  platforms. Customers should review them before using the
                  platforms.
                </small>
              </p>
              <p className="alert-txt">
                <small>
                  Please be advised that Regions Bank accepts
                  payments using a debit card but does NOT accept credit cards.
                </small>
              </p>
            </div>
          </div>
        </Modal>
      )}
      <ResendLoanDocsPopup open={open} onClose={() => setOpen(false)} />
    </Layout>
  )
}

export default HomeOwnerPage

export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      page(id: $databaseId, idType: DATABASE_ID) {
        title
        date
        content(format: RENDERED)
        featuredImage {
          node {
            altText
            title(format: RENDERED)
            mediaItemUrl
            slug
          }
        }

        top_banner {
          banner {
            backgroundImage {
              sourceUrl
              altText
            }
            bannerLinks {
              fieldGroupName
              links {
                title
                url
              }
            }
          }
        }

        home_owner {
          makePaymentWay
          paymentBanner {
            sourceUrl
            altText
          }
          benefitOption {
            fieldGroupName
            optionTitle
            optionIcon {
              id
              sourceUrl
              altText
              title
              caption
            }
          }
        }

        accordion {
          tabpanel {
            fieldGroupName
            tabcontent
            tablabel
          }
        }

        video_section {
          video {
            videoUrl
            videoBanner {
              sourceUrl
            }
          }
        }
      }
    }
  }
`
